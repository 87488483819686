



































































































import Vue from 'vue';
import AdminPaymentGatewayService from '@/services/admin/AdminPaymentGatewayService';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import PayPal from 'vue-paypal-checkout';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

const validations = {
  amount: { required },
  environmentType: { required },
  paymentProvider: { required },
};

export default Vue.extend({
  components: { ValidatedInput, ValidatedSelect, PayPal, StripeCheckout },
  validations,
  mixins: [validationMixin],
  data() {
    return {
      clientToken: '',
      loading: false,
      amount: '1,00',
      environmentType: '',
      environmentTypeOptions: [
        { label: '', value: '' },
        { label: 'Sandbox', value: 'Sandbox' },
        { label: 'Live', value: 'Live' },
      ],
      paymentProvider: '',
      paymentProviderOptions: [
        { label: '', value: '' },
        { label: 'PayPal', value: 'PayPal' },
        { label: 'BrainTree', value: 'BrainTree' },
        { label: 'Stripe', value: 'Stripe' },
      ],
      paypalOptions: { flow: 'vault' },
      payPalSandboxClientId: '',
      payPalLiveClientId: '',
      ready: false,
      payPalReturnData: {},
      lineItems: [
        {
          price_data: {
            unit_amount: 4000,
            currency: 'usd',
            product_data: {
              name: 'Test Product',
            },
          },
          quantity: 1,
        },
      ],
      successURL: 'your-success-url',
      cancelURL: 'your-cancel-url',
    };
  },
  watch: {
    environmentType() {
      this.loadData();
    },
  },
  computed: {
    amountNumber(): string {
      return this.amount.replaceAll(',', '.');
    },
    payPalenvironmentType(): string {
      return this.environmentType == 'Sandbox' ? 'sandbox' : 'production';
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      AdminPaymentGatewayService.GetControlTransactionData(this.environmentType).then((resp) => {
        this.clientToken = resp.value.clientToken;
        this.loading = false;
        this.payPalSandboxClientId = resp.value.payPalSandboxClientId;
        this.payPalLiveClientId = resp.value.payPalLiveClientId;
        this.ready = true;
      });
    },

    onSuccess(data: { nonce: string }) {
      console.log('onSuccess', data);
      AdminPaymentGatewayService.executeControlTransaction({ ...data, amount: this.amount }).then((resp) => {
        console.log(resp);
        this.$router.push({ name: 'AdminTransactionIndex' });
      });
    },

    onError(error: String) {
      console.log('onError', error);
    },

    onPaymentAuthorized(event: {}) {
      this.payPalReturnData = event;
      console.log('onPaymentAuthorized', event);
    },

    onPaymentCompleted(event: {}) {
      this.payPalReturnData = event;
      console.log('onPaymentCompleted', event);
    },

    onPaymentCancelled(event: {}) {
      this.payPalReturnData = event;
      console.log('onPaymentCancelled', event);
    },
  },
});
