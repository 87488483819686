import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/PaymentGateway/';

class AdminPaymentGatewayService {
  executeControlTransaction(data: { nonce: string; amount: number | string }) {
    data.amount = data.amount.toString();
    return ApiService.post(`${API_URL}ExecuteControlTransaction`, data);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }

  GetControlTransactionData(sandboxMode: string) {
    return ApiService.get(`${API_URL}ControlTransaction?sandboxMode=${sandboxMode}`);
  }
}

export default new AdminPaymentGatewayService();
